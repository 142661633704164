import React from "react"

import Layout from "../../components/Layout"
import TimetableNotice from "../../components/Content/TimetableNotice"
import DayTours from "../../components/DayTours"

import SEO from "../../components/SEO"

const DayToursPage = (props) => {
  return (
    <Layout location={props.location}>
      <SEO
        title="Day Tours"
        description="Our Day Trips are a great day out with friends or to meet new people"
      />
      <TimetableNotice />
      <DayTours perPage={10} />
    </Layout>
  )
}

export default DayToursPage
